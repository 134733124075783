<template>
  <div class="overflow-auto">
    <pro-document-breadcrumbs :breadcrumbs="breadcrumbs" user-role="procurer" />
    <div class="q-pa-md">
      <pro-deck
        :title="$t('Form.Section.DownloadPage')"
        :cards="sections"
        v-slot="{ card: section }"
        hide-count-badge
      >
        <pro-deck-card :title="section.name" expandable>
          <q-list>
            <q-item
              v-for="(file, index) in section.files"
              :key="index"
              class="item-border-top"
            >
              <pro-file
                :file="file"
                show-download-button
                :on-download-file="handleDownloadFile"
              />
            </q-item>
          </q-list>
        </pro-deck-card>
      </pro-deck>
    </div>
  </div>
</template>

<script>
import ProDocumentBreadcrumbs from "@/components/PROSmart/ProDocumentBreadcrumbs";
import ProDeck from "@/components/PROSmart/Deck/ProDeck";
import ProDeckCard from "@/components/PROSmart/Deck/ProDeckCard";
import ProFile from "@/components/PROSmart/ProFile";

export default {
  components: { ProDocumentBreadcrumbs, ProDeck, ProDeckCard, ProFile },
  data() {
    return {
      breadcrumbs: [
        {
          name: "menu.Download",
          icon: "PROSmart-download",
        },
      ],
      sections: [],
    };
  },
  methods: {
    handleDownloadFile(file) {
      this.$proSmart.download.downloadDownloadPageFile(this, file.fileId);
    },
  },
  created() {
    this.$proSmart.commonPage.getDownloadPageFileList(this).then((fileList) => {
      this.sections = fileList.reduce((arr, item) => {
        item.description = this.$t(item.description);

        let section = arr.find((s) => s.name === item.section);
        if (section === undefined) {
          section = {
            key: item.id,
            name: item.section,
            files: [],
          };
          arr.push(section);
        }
        section.files.push(item);
        return arr;
      }, []);
    });
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/quasar.variables";
::v-deep .q-card__section--vert {
  padding: 0;
}
.item-border-top {
  border-top: 1px solid $primary;
}
</style>
